
:root {
    --default-margin-tiny: 5px;
    --default-margin-small: 10px;
    --default-margin: 20px;
    --default-margin-medium: 30px;
    --default-margin-large: 45px;
    --white-color: #d9dade;
    --app-bar-height: 56px;
    --app-bar-height-web: 64px;
    --app-bar-tabs-height: 104px;
    --app-bar-tabs-height-web: 112px;
}

@media (max-width: 350px) {
    :root {
        --default-margin-tiny: 3px;
        --default-margin-small: 5px;
        --default-margin: 10px;
        --default-margin-medium: 15px;
        --default-margin-large: 23px;
    }
}

.App {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    height: 100vh;
}

.App_content {
    top: var(--app-bar-height);
    height: calc(100vh - var(--app-bar-height));
    position: relative;
    display: flex;
    flex-flow: column;
    margin-left: var(--default-margin);
    margin-right: var(--default-margin);
}

@media (min-width: 800px) {
    .App {
        height: calc(100vh - var(--app-bar-tabs-height-web));
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    .App_content {
        top: var(--app-bar-height-web);
        position: relative;
    }
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}
